@charset "UTF-8";
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.3/font/bootstrap-icons.css");
.bg-catskillwhite {
  background-color: #F7F9FB;
}

/*=== Media Query ===*/
.logo-reveal {
  position: relative;
  scale: 0.5;
  margin-left: -1rem;
  margin-right: -1rem;
}
.logo-reveal .main-logo {
  width: 320px;
  height: 40px;
}
.logo-reveal .left-bar-wrapper,
.logo-reveal .right-bar-wrapper {
  transition: position 2s;
  position: absolute;
  display: inline-block;
}
.logo-reveal .left-bar-wrapper:before,
.logo-reveal .right-bar-wrapper:before {
  content: "";
  display: inline-block;
  z-index: 1;
  position: absolute;
  top: 0;
  bottom: 0;
  background-color: #e26e0d;
  transition: width 2s ease-out;
  width: 180px;
  height: 40px;
}
.logo-reveal .left-bar-wrapper .left-bar,
.logo-reveal .left-bar-wrapper .right-bar,
.logo-reveal .right-bar-wrapper .left-bar,
.logo-reveal .right-bar-wrapper .right-bar {
  display: inline-block;
  width: 23px;
  height: 40px;
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 2;
}
.logo-reveal .left-bar-wrapper:before {
  left: -23px;
}
.logo-reveal .left-bar-wrapper .left-bar {
  left: 150px;
  transition: left 2s ease-out;
}
.logo-reveal .right-bar-wrapper:before {
  right: -23px;
}
.logo-reveal .right-bar-wrapper .right-bar {
  right: 150px;
  transition: right 2s ease-out;
}
.logo-reveal.reveal .left-bar-wrapper:before,
.logo-reveal.reveal .right-bar-wrapper:before {
  width: 0;
}
.logo-reveal.reveal .left-bar-wrapper .left-bar {
  left: -26px;
}
.logo-reveal.reveal .right-bar-wrapper .right-bar {
  right: -26px;
}

.navbar .brand-logo .logo-reveal {
  margin-left: -4rem;
}

.logo {
  height: 1.5rem;
}

.logo-small {
  height: 1.5rem;
}

.main-image {
  background-image: url("./images/CRAFTURE_DB_Render_001.png");
}

.theme-color-orange {
  color: rgb(210, 116, 47) !important;
}

.bg-theme-color-orange {
  background-color: rgb(210, 116, 47) !important;
}

.bg-gray-color {
  background-color: rgb(210, 210, 210);
}

.bg-gray-color-transparant {
  background-color: rgba(210, 210, 210, 0.4) !important;
}

.pb-15 {
  padding-bottom: 1.5rem !important;
}

/* Make logo smaller for smaller screens */
@media only screen and (min-width: 576px) {
  .logo {
    height: 2rem;
  }
}
.bg-light-color-orange {
  background-color: rgba(250, 229, 218, 0.4) !important;
}

/* rgb(210 116 47) */
.inner-contact-form-area-1 {
  background: url(../image/latest/contact-bg-2.png);
  background-color: rgba(0, 0, 0, 0);
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-size: cover;
  background-origin: padding-box;
  background-clip: border-box;
}

/* Location search area */
.center-flex {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1em;
}

.text-white {
  color: white;
}

.form-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  width: 100%;
  max-width: 768px;
}

.form-section {
  margin: 0.5rem;
}

.form-section-heading {
  font-size: 1rem;
  font-weight: 600;
  text-align: center;
}

.max-width {
  max-width: 576px;
}

/* Custom by @Nevron */
@media (max-width: 991px) {
  .reveal-header .mobile-menu-trigger span {
    background-color: black !important;
  }
  .reveal-header .mobile-menu-trigger span:before,
  .reveal-header .mobile-menu-trigger span:after {
    background-color: black !important;
  }
}
.section__heading h2 {
  font-size: 2.2rem;
}

.header-btn .dropdown-toggle {
  background: none;
}
.header-btn .dropdown-toggle:focus {
  box-shadow: none;
}
.header-btn .dropdown-toggle:hover {
  box-shadow: none;
}

.tab-content ul {
  list-style-type: disc;
}
.tab-content ul li {
  list-style-type: disc;
}

@media all and (max-width: 768px) {
  .footer-logo {
    text-align: center;
  }
  .footer-logo .logo-reveal {
    scale: 0.7;
  }
}
.footer-copyright-wrapper {
  margin-top: 2rem;
}

@media all and (max-width: 767px) {
  .footer-content-l5 .copyright-text-l5 {
    margin-bottom: 2rem;
  }
}
@media all and (min-width: 992px) {
  .footer-content-l5 .copyright-text-l5 {
    padding-left: 3rem;
  }
}
@media all and (min-width: 1200px) {
  .footer-content-l5 .copyright-text-l5 {
    padding-left: 4rem;
  }
}
@media all and (min-width: 1400px) {
  .footer-content-l5 .copyright-text-l5 {
    padding-left: 5rem;
  }
}

@media all and (max-width: 767px) {
  .product-carousel-block .product-carousel {
    padding: 2rem;
  }
  .product-carousel-block .product-carousel .slick-buttons.slick-prev {
    left: 0;
  }
  .product-carousel-block .product-carousel .slick-buttons.slick-next {
    right: 0;
  }
  .product-details-vertical-slider {
    padding: 1rem 2rem;
  }
  .product-details-vertical-slider .slick-buttons.slick-prev {
    left: 0;
  }
  .product-details-vertical-slider .slick-buttons.slick-next {
    right: 0;
  }
}
@media all and (min-width: 1200px) {
  .custom-nav .site-menu-main .nav-link-item {
    padding: 10px 20px !important;
  }
}
.product-carousel-block {
  padding: 1rem 0 1rem;
  /* Slick slider styles */
}
.product-carousel-block .product-carousel {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}
.product-carousel-block .slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}
.product-carousel-block .slick-list:focus {
  outline: none;
}
.product-carousel-block .slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}
.product-carousel-block .slick-slider .slick-track,
.product-carousel-block .slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
.product-carousel-block .slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.product-carousel-block .slick-track:before,
.product-carousel-block .slick-track:after {
  display: table;
  content: "";
}
.product-carousel-block .slick-track:after {
  clear: both;
}
.product-carousel-block .slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  margin: 0 10px;
  display: block;
  overflow: hidden;
}
.product-carousel-block [dir=rtl] .slick-slide {
  float: right;
}
.product-carousel-block .slick-slide img {
  display: block;
  max-width: 100%;
  height: auto;
  transition: transform 0.2s ease-out;
}
.product-carousel-block .slick-slide img:hover {
  transform: scale(1.1);
}
.product-carousel-block .slick-dots {
  display: block;
  text-align: center;
  margin: 10px 0;
  padding: 0;
  list-style: none;
}
.product-carousel-block .slick-dots li {
  position: relative;
  display: inline-block;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}
.product-carousel-block .slick-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 10px;
  height: 10px;
  padding: 5px;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: none;
  background: #bbb;
  border-radius: 50%;
}
.product-carousel-block .slick-dots li button:hover,
.product-carousel-block .slick-dots li button:focus {
  outline: none;
}
.product-carousel-block .slick-dots li button:hover:before,
.product-carousel-block .slick-dots li button:focus:before {
  opacity: 1;
}
.product-carousel-block .slick-dots li button:before {
  font-size: 6px;
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: "•";
  text-align: center;
  opacity: 0.25;
  color: #fff;
  transition: all 0.3s ease;
}
.product-carousel-block .slick-dots li.slick-active button:before {
  opacity: 0.75;
  color: #fff;
}

@media all and (max-width: 768px) {
  .product-details-slider {
    display: none;
  }
  .product-details-slider.slick-initialized {
    display: none;
  }
}
@media all and (min-width: 769px) {
  .product-details-slider {
    display: none;
  }
  .product-details-slider.slick-initialized {
    display: block;
  }
  .product-details-vertical-slider {
    display: none;
  }
  .product-details-vertical-slider.slick-initialized {
    display: block;
  }
}
/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
       About Us Banner
<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.about-us-banner {
  padding-top: 100px;
  padding-bottom: 60px;
}
@media (min-width: 480px) {
  .about-us-banner {
    padding-top: 115px;
  }
}
@media (min-width: 768px) {
  .about-us-banner {
    padding-top: 137px;
    padding-bottom: 80px;
  }
}
@media (min-width: 992px) {
  .about-us-banner {
    padding-top: 213px;
    padding-bottom: 120px;
  }
}

.about-us-banner-content h2 {
  color: #303334;
  font-size: 20px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -1.5px;
  line-height: 31px;
}
@media (min-width: 576px) {
  .about-us-banner-content h2 {
    font-size: 30px;
    line-height: 42px;
  }
}
@media (min-width: 768px) {
  .about-us-banner-content h2 {
    font-size: 39px;
    line-height: 47px;
  }
}
@media (min-width: 992px) {
  .about-us-banner-content h2 {
    font-size: 43px;
    line-height: 62px;
  }
}
.about-us-banner-content p {
  color: #d2742f;
  font-size: 20px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.66px;
  line-height: 34px;
  margin-top: 15px;
}
.about-us-banner-content .btn {
  width: 180px;
  height: 60px;
  border-radius: 6px;
  background-color: #d2742f;
  color: white;
  font-size: 16px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -0.53px;
  line-height: normal;
  margin-top: 33px;
}

.inner-pages-menu li .nav-link {
  color: #d2742f !important;
}

.log-in-btn-inner {
  color: #d2742f !important;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
       About Us Content-1
<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.about-content-1 {
  padding-bottom: 50px;
}
@media (min-width: 768px) {
  .about-content-1 {
    padding-bottom: 75px;
  }
}
@media (min-width: 992px) {
  .about-content-1 {
    padding-bottom: 120px;
  }
}
.about-content-1 .about-content-1-right {
  margin-top: 30px;
}
@media (min-width: 768px) {
  .about-content-1 .about-content-1-right {
    margin-top: 40px;
  }
}
@media (min-width: 992px) {
  .about-content-1 .about-content-1-right {
    margin-top: 50px;
  }
}

.about-content-1-bottom {
  margin-top: 45px;
}
@media (min-width: 768px) {
  .about-content-1-bottom {
    margin-top: 65px;
  }
}
@media (min-width: 992px) {
  .about-content-1-bottom {
    margin-top: 115px;
  }
}

.about-content-1-heading h2 {
  color: #303334;
}

.about-content-1-content {
  margin-bottom: -35px;
}
@media (min-width: 1600px) {
  .about-content-1-content {
    padding-right: 65px;
  }
}
.about-content-1-content p {
  color: #d2742f;
  font-size: 20px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.66px;
  line-height: 34px;
  margin-bottom: 35px;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
       About Us Content-1
<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.about-content-2 {
  padding-top: 60px;
  padding-bottom: 60px;
}
@media (min-width: 768px) {
  .about-content-2 {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}
@media (min-width: 992px) {
  .about-content-2 {
    padding-top: 110px;
    padding-bottom: 130px;
  }
}

.about-content-2-img-2 {
  margin-top: 25px;
}
@media (min-width: 992px) {
  .about-content-2-img-2 img {
    padding-left: 70px;
  }
}

.about-content-2-right-img {
  margin-top: 40px;
}
@media (min-width: 768px) {
  .about-content-2-right-img {
    margin-top: 50px;
  }
}
@media (min-width: 992px) {
  .about-content-2-right-img {
    margin-top: 75px;
  }
}

@media (min-width: 768px) {
  .about-content-2-content {
    padding-top: 0;
  }
}
.about-content-2-content p {
  margin-bottom: 32px;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
       About Us page Team
<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.about-us-temam-area {
  padding-top: 45px;
  padding-bottom: 50px;
}
@media (min-width: 768px) {
  .about-us-temam-area {
    padding-top: 65px;
    padding-bottom: 70px;
  }
}
@media (min-width: 992px) {
  .about-us-temam-area {
    padding-top: 115px;
    padding-bottom: 120px;
  }
}
.about-us-temam-area .section__heading {
  margin-bottom: 45px;
}
@media (min-width: 768px) {
  .about-us-temam-area .section__heading {
    margin-bottom: 55px;
  }
}
@media (min-width: 992px) {
  .about-us-temam-area .section__heading {
    margin-bottom: 65px;
  }
}

.about-team-item {
  margin-bottom: -40px;
}
@media (min-width: 768px) {
  .about-team-item {
    margin-bottom: -70px;
  }
}
.about-team-item .col-lg-3 {
  margin-bottom: 40px;
}
@media (min-width: 768px) {
  .about-team-item .col-lg-3 {
    margin-bottom: 70px;
  }
}

.about-team-card__image {
  position: relative;
  overflow: hidden;
  padding-top: 2rem;
  height: 267px;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (min-width: 1200px) {
  .about-team-card__image {
    height: 350px;
  }
}
.about-team-card__image img {
  width: 100%;
  height: 100%;
  position: absolute;
  object-fit: cover;
  z-index: 0;
}
.about-team-card__content {
  margin-top: 28px;
}
.about-team-card__content h6 {
  color: #303334;
  font-size: 22px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -0.73px;
  line-height: normal;
}
.about-team-card__content span {
  color: #d2742f;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.53px;
  line-height: normal;
}
@media (min-width: 576px) {
  .about-team-card__view-open {
    padding: 90px 22px;
  }
}
@media (min-width: 768px) {
  .about-team-card__view-open {
    padding: 167px 37px;
  }
}
@media (min-width: 992px) {
  .about-team-card__view-open {
    padding: 72px 22px;
  }
}
@media (min-width: 1200px) {
  .about-team-card__view-open {
    padding: 116px 22px;
  }
}
@media (min-width: 1366px) {
  .about-team-card__view-open {
    padding: 116px 22px;
  }
}
@media (min-width: 1600px) {
  .about-team-card__view-open {
    padding: 145px 22px;
  }
}
.about-team-card__view-open h4 {
  color: #303334;
  font-size: 22px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -0.73px;
  line-height: 30px;
}
.about-team-card__view-open .btn {
  color: #d2742f;
  font-size: 16px;
  font-weight: 700;
  min-width: 88px;
  letter-spacing: -0.53px;
  line-height: normal;
  height: 22px;
  border-radius: 0;
  border-bottom: 2px solid #d2742f;
  padding: 0px 0px 15px 0px !important;
  margin-top: 25px;
}
.about-team-card__view-open .btn:hover {
  color: #d2742f !important;
}

@media (min-width: 992px) {
  .min-height-100vh {
    min-height: 100vh;
  }
  .no-gutters {
    margin-right: 0;
    margin-left: 0;
  }
  .fixed-right-bg {
    position: fixed;
    top: 0;
    right: 0;
    width: 50%;
  }
  .max-w-465 {
    max-width: 465px;
  }
}
.sign-in-menu {
  padding-top: 20px;
}

.full-bg-position {
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: center !important;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
    landing-8 contact area
<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.contact-area-l8-1 {
  padding-top: 45px;
}
@media (min-width: 768px) {
  .contact-area-l8-1 {
    padding-top: 65px;
  }
}
@media (min-width: 992px) {
  .contact-area-l8-1 {
    padding-top: 105px;
  }
}
.contact-area-l8-1 .section__heading h2 {
  color: white;
}
.contact-area-l8-1 .section__heading p {
  color: #EAECED;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.5px;
  line-height: 28px;
}
@media (min-width: 480px) {
  .contact-area-l8-1 .section__heading p {
    padding: 0px 50px;
  }
}
@media (min-width: 576px) {
  .contact-area-l8-1 .section__heading p {
    padding: 0px 70px;
  }
}
.contact-area-l8-1 .contact-map {
  margin-top: 30px;
  margin-bottom: 30px;
}
@media (min-width: 768px) {
  .contact-area-l8-1 .contact-map {
    margin-top: 30px;
    margin-bottom: 50px;
  }
}
@media (min-width: 992px) {
  .contact-area-l8-1 .contact-map {
    margin-top: 30px;
    margin-bottom: 110px;
  }
}
.contact-area-l8-1 .contact-map img {
  width: 100%;
}
@media (min-width: 1200px) {
  .contact-area-l8-1 .contact-map img {
    width: initial;
  }
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
    landing-9 contact area
<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.contact-l9-1__form-area {
  box-shadow: 0 23px 78px rgba(0, 0, 0, 0.09);
  border-radius: 22px;
  background-color: white;
  padding: 20px 15px 20px 15px;
  margin-top: -100px;
}
@media (min-width: 480px) {
  .contact-l9-1__form-area {
    padding: 30px 30px 40px 30px;
  }
}
@media (min-width: 768px) {
  .contact-l9-1__form-area {
    padding: 67px 85px 60px 85px;
    margin-top: -200px;
  }
}
@media (min-width: 992px) {
  .contact-l9-1__form-area {
    margin-top: -320px;
  }
}
.contact-l9-1__heading h2 {
  color: #0D152E;
  font-size: 36px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -1.39px;
  line-height: normal;
}
@media (min-width: 576px) {
  .contact-l9-1__heading h2 {
    font-size: 42px;
  }
}
.contact-l9-1__heading p {
  color: #81838C;
  font-size: 20px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.66px;
  line-height: 34px;
  margin-top: 20px;
}
.contact-l9-1__input, .contact-l9-1 textarea {
  margin-top: 60px;
}
.contact-l9-1__input .form-group, .contact-l9-1 textarea .form-group {
  margin-bottom: 20px;
}
.contact-l9-1__input input, .contact-l9-1__input textarea, .contact-l9-1 textarea input, .contact-l9-1 textarea textarea {
  color: #9EA1AB;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.53px;
  line-height: normal;
  width: 100%;
  height: 60px;
  border-radius: 107px;
  border: 1px solid #E5E5E5;
  background-color: #F7F9FB;
  padding-left: 30px;
}
.contact-l9-1__input .begin-advance-input, .contact-l9-1__input textarea, .contact-l9-1 textarea .begin-advance-input, .contact-l9-1 textarea textarea {
  width: 100%;
}
.contact-l9-1__input .begin-advance-input input, .contact-l9-1__input .begin-advance-input textarea, .contact-l9-1__input textarea input, .contact-l9-1__input textarea textarea, .contact-l9-1 textarea .begin-advance-input input, .contact-l9-1 textarea .begin-advance-input textarea, .contact-l9-1 textarea textarea input, .contact-l9-1 textarea textarea textarea {
  padding-left: 60px;
}
.contact-l9-1__input .begin-advance-input .beginer, .contact-l9-1__input textarea .beginer, .contact-l9-1 textarea .begin-advance-input .beginer, .contact-l9-1 textarea textarea .beginer {
  width: 100%;
  position: relative;
}
@media (min-width: 576px) {
  .contact-l9-1__input .begin-advance-input .beginer, .contact-l9-1__input textarea .beginer, .contact-l9-1 textarea .begin-advance-input .beginer, .contact-l9-1 textarea textarea .beginer {
    width: 48%;
  }
}
.contact-l9-1__input .begin-advance-input .advanced, .contact-l9-1__input textarea .advanced, .contact-l9-1 textarea .begin-advance-input .advanced, .contact-l9-1 textarea textarea .advanced {
  width: 100%;
  position: relative;
}
@media (min-width: 576px) {
  .contact-l9-1__input .begin-advance-input .advanced, .contact-l9-1__input textarea .advanced, .contact-l9-1 textarea .begin-advance-input .advanced, .contact-l9-1 textarea textarea .advanced {
    width: 48%;
  }
}
.contact-l9-1__input .begin-advance-input .check-form, .contact-l9-1__input textarea .check-form, .contact-l9-1 textarea .begin-advance-input .check-form, .contact-l9-1 textarea textarea .check-form {
  position: absolute;
  top: 50%;
  left: 30px;
  transform: translate(-50%, -50%);
}
.contact-l9-1__input .get-start-btn .btn, .contact-l9-1 textarea .get-start-btn .btn {
  width: 100%;
  height: 60px;
  border-radius: 30px;
  background-color: #303334;
  color: white;
  font-size: 16px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -0.53px;
  line-height: normal;
  margin-top: 20px;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
  Contact Us page 
<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.contact-us-area {
  padding-top: 85px;
}
@media (min-width: 768px) {
  .contact-us-area {
    padding-top: 113px;
  }
}
@media (min-width: 992px) {
  .contact-us-area {
    padding-top: 158px;
  }
}
.contact-us-area .contact-us-banner {
  margin-bottom: 40px;
}
@media (min-width: 768px) {
  .contact-us-area .contact-us-banner {
    margin-bottom: 55px;
  }
}
@media (min-width: 992px) {
  .contact-us-area .contact-us-banner {
    margin-bottom: 70px;
  }
}
.contact-us-area .contact-us-banner h2 {
  color: #0D152E;
  font-size: 36px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -1.98px;
  line-height: normal;
  margin-bottom: 10px;
}
@media (min-width: 576px) {
  .contact-us-area .contact-us-banner h2 {
    font-size: 40px;
  }
}
@media (min-width: 768px) {
  .contact-us-area .contact-us-banner h2 {
    font-size: 60px;
  }
}
.contact-us-area .contact-us-banner p {
  color: #81838C;
  font-size: 20px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.66px;
  line-height: 34px;
}

.contact-us-form-area {
  position: relative;
  padding-bottom: 60px;
}
@media (min-width: 768px) {
  .contact-us-form-area {
    padding-bottom: 80px;
  }
}
@media (min-width: 992px) {
  .contact-us-form-area {
    padding-bottom: 130px;
  }
}
.contact-us-form-area:after {
  position: absolute;
  content: "";
  background: #303334;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: -1;
  height: 75%;
}

.contact-us-form {
  box-shadow: 0 -20px 79px rgba(0, 0, 0, 0.08);
  border-radius: 15px;
  background-color: white;
  padding: 33px 30px 40px 30px;
}
@media (min-width: 576px) {
  .contact-us-form {
    padding: 55px 50px 55px 50px;
  }
}
@media (min-width: 768px) {
  .contact-us-form {
    padding: 55px 75px 55px 75px;
  }
}
@media (min-width: 768px) {
  .contact-us-form > div:nth-child(2) {
    margin-right: 5%;
  }
  .contact-us-form > div:nth-child(2), .contact-us-form > div:nth-child(3) {
    width: 47%;
    display: inline-block;
  }
}
@media (min-width: 768px) {
  .contact-us-form.inquiry-form > div:nth-child(2), .contact-us-form.inquiry-form > div:nth-child(5) {
    margin-right: 5%;
  }
  .contact-us-form.inquiry-form > div:nth-child(2), .contact-us-form.inquiry-form > div:nth-child(3), .contact-us-form.inquiry-form > div:nth-child(5), .contact-us-form.inquiry-form > div:nth-child(6) {
    width: 47%;
    display: inline-block;
  }
}
.contact-us-form .nice-select {
  width: 100%;
  height: 50px;
  line-height: 50px;
  padding-left: 15px;
  color: #0D152E;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.53px;
  margin-bottom: 1.5rem;
}
.contact-us-form .nice-select .list {
  width: 100%;
  height: auto;
  overflow-y: scroll;
}
.contact-us-form .nice-select:after {
  border-bottom: 2px solid #000;
  border-right: 2px solid #000;
  width: 6px;
  height: 6px;
  right: 16px;
}
.contact-us-form label {
  color: #0D152E;
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  letter-spacing: -0.6px;
  line-height: normal;
  margin-bottom: 20px;
  display: block;
}
.contact-us-form input::placeholder, .contact-us-form textarea::placeholder {
  color: #81838C;
  opacity: 1;
}
.contact-us-form input, .contact-us-form textarea {
  color: #81838C;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.53px;
  line-height: normal;
  height: 50px;
  border-radius: 5px;
  border: 1px solid #EAECED;
  background-color: white;
  margin-bottom: 28px;
  padding-left: 15px;
  width: 100%;
}
.contact-us-form textarea {
  height: 220px !important;
  padding-top: 15px;
}
.contact-us-form .btn {
  width: auto;
  height: 60px;
  border-radius: 6px;
  background-color: #d2742f;
  color: white;
  font-size: 16px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -0.53px;
  line-height: normal;
  text-align: center;
  margin-top: 1.5rem;
}

.contact-get-in-touch-area {
  margin-bottom: -30px;
  padding-top: 60px;
}
@media (min-width: 768px) {
  .contact-get-in-touch-area {
    padding-top: 80px;
  }
}
@media (min-width: 992px) {
  .contact-get-in-touch-area {
    padding-top: 120px;
  }
}
.contact-get-in-touch-area .get-in-touch-single {
  margin-bottom: 30px;
}
.contact-get-in-touch-area .get-in-touch-single .icon {
  width: 80px;
  height: 80px;
  line-height: 80px;
  text-align: center;
  border-radius: 8px;
  background-color: #101C3D;
}
.contact-get-in-touch-area .get-in-touch-single .content h4 {
  color: white;
  font-size: 22px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -0.73px;
  margin-top: 35px;
  margin-bottom: 25px;
}
.contact-get-in-touch-area .get-in-touch-single .content p {
  color: #687497;
  font-size: 18px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.59px;
  line-height: 30px;
}
.contact-get-in-touch-area .get-in-touch-single .content a {
  text-decoration: none;
  font-weight: 700;
  color: #303334;
}

.contact-us-supp-btn .btn {
  width: 100%;
  height: 60px;
  border-radius: 6px;
  background-color: #303334;
  color: white;
  font-size: 16px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -0.53px;
  line-height: normal;
  margin-top: 30px;
}
@media (min-width: 576px) {
  .contact-us-supp-btn .btn {
    width: 180px;
  }
}
@media (min-width: 768px) {
  .contact-us-supp-btn .btn {
    margin-top: 60px;
  }
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
  Sign In page 
<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.sign-in-menu .navbar-toggler {
  display: none;
}

.sign-in-area {
  padding-top: 40px;
  padding-bottom: 60px;
}
@media (min-width: 768px) {
  .sign-in-area {
    padding-top: 60px;
    padding-bottom: 80px;
  }
}
@media (min-width: 992px) {
  .sign-in-area {
    padding-top: 0px;
    padding-bottom: 0px;
  }
}

@media (min-width: 992px) {
  .sign-in-content {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    right: 30px;
    width: 437px;
  }
}
@media (min-width: 1200px) {
  .sign-in-content {
    right: 105px;
    width: 465px;
  }
}

.sign-in-tesimonial-card {
  margin-top: 40px;
  padding: 30px;
}
@media (min-width: 768px) {
  .sign-in-tesimonial-card {
    padding: 45px;
  }
}
@media (min-width: 992px) {
  .sign-in-tesimonial-card {
    margin-top: 0;
    padding: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 30px;
  }
}
@media (min-width: 1200px) {
  .sign-in-tesimonial-card {
    left: 75px;
  }
}
.sign-in-tesimonial-card img {
  margin-right: 15px;
}
.sign-in-tesimonial-card h4 {
  color: white;
  font-size: 20px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -0.66px;
  line-height: normal;
}
.sign-in-tesimonial-card span {
  color: #687497;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.53px;
  line-height: normal;
}
.sign-in-tesimonial-card p {
  color: white;
  font-size: 18px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.66px;
  line-height: 32px;
  margin-top: 25px;
  margin-bottom: 30px;
}
@media (min-width: 480px) {
  .sign-in-tesimonial-card p {
    font-size: 20px;
    line-height: 34px;
  }
}
@media (min-width: 768px) {
  .sign-in-tesimonial-card p {
    margin-bottom: 40px;
  }
}
@media (min-width: 992px) {
  .sign-in-tesimonial-card p {
    margin-bottom: 50px;
  }
}
.sign-in-tesimonial-card .testimonial__star {
  margin: 0px -4px 0px -4px;
}
.sign-in-tesimonial-card .testimonial__star i {
  font-size: 24px;
  color: #fbb040;
  margin: 0px 4px;
}

.sign-in-content h2 {
  color: #0D152E;
  font-size: 34px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -1.58px;
  line-height: 46px;
  margin-bottom: 40px;
}
@media (min-width: 768px) {
  .sign-in-content h2 {
    font-size: 40px;
    line-height: 52px;
    margin-bottom: 50px;
  }
}
@media (min-width: 992px) {
  .sign-in-content h2 {
    font-size: 48px;
    line-height: 60px;
    margin-bottom: 70px;
  }
}
.sign-in-content label {
  color: #0d152e;
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  letter-spacing: -0.6px;
  line-height: normal;
  margin-bottom: 20px;
}
.sign-in-content input, .sign-in-content textarea::placeholder {
  color: #81838C;
  opacity: 1;
}
.sign-in-content input, .sign-in-content textarea {
  color: #81838C;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.53px;
  line-height: normal;
  height: 50px;
  border-radius: 5px;
  border: 1px solid #EAECED;
  background-color: white;
  margin-bottom: 28px;
  padding-left: 15px;
}
.sign-in-content .check-form {
  margin-right: 15px;
}
.sign-in-content .check-form .remember-text {
  color: #0D152E;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.53px;
  line-height: normal;
}
.sign-in-content .check-form span {
  margin-right: 12px;
}

.forgot-pass-text h6 {
  color: #303334;
  font-size: 16px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -0.53px;
  line-height: normal;
}
.forgot-pass-text a {
  text-decoration: none;
  font-weight: 700;
}

.sign-in-log-btn .btn {
  width: 100%;
  height: 61px;
  border-radius: 6px;
  background-color: #303334;
  color: white;
  font-size: 16px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -0.53px;
  line-height: normal;
  margin-top: 25px;
}

.create-new-acc-text p {
  color: #81838C;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.53px;
  line-height: normal;
  margin-top: 25px;
}
.create-new-acc-text a {
  text-decoration: none;
  color: #303334;
  font-size: 16px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -0.53px;
  line-height: normal;
}

@media (min-width: 992px) {
  .sign-up-area {
    padding-top: 100px;
    padding-bottom: 100px;
  }
}
@media (min-width: 1600px) {
  .sign-up-area {
    padding-top: 0px;
    padding-bottom: 0px;
  }
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
    Blog Home-6     CSS 
        <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.blog-area-6 {
  padding-top: 60px;
  padding-bottom: 60px;
}
@media (min-width: 768px) {
  .blog-area-6 {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}
@media (min-width: 992px) {
  .blog-area-6 {
    padding-top: 130px;
    padding-bottom: 130px;
  }
}
.blog-area-6 .section__heading-2 h4 {
  color: #303334;
  font-size: 12px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: 1.2px;
  line-height: normal;
  text-align: center;
  text-transform: uppercase;
}
.blog-area-6 .section__heading-2 h2 {
  color: #0D152E;
  font-size: 42px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -1.89px;
  line-height: normal;
  margin-top: 30px;
  margin-bottom: 0px;
}
@media (min-width: 768px) {
  .blog-area-6 .section__heading-2 h2 {
    margin-bottom: 60px;
  }
}
@media (min-width: 992px) {
  .blog-area-6 .section__heading-2 h2 {
    margin-bottom: 80px;
  }
}
.blog-area-6 .blog-all-items {
  margin-bottom: -45px;
}
.blog-area-6 .blog-card {
  height: 100%;
  margin-bottom: 45px;
}
.blog-area-6 .blog-card .blog-image img {
  border-radius: 15px 15px 0px 0px;
}
.blog-area-6 .blog-card .blog-content {
  background: #fff;
  padding: 30px;
  border-radius: 15px;
}
@media (min-width: 480px) {
  .blog-area-6 .blog-card .blog-content {
    padding: 35px 45px 45px 45px;
  }
}
.blog-area-6 .blog-card .blog-content h4 {
  font-size: 20px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -0.66px;
  line-height: 34px;
}
.blog-area-6 .blog-card .blog-content h4 a {
  text-decoration: none;
  color: #0D152E;
}
.blog-area-6 .blog-card .blog-content p {
  color: #81838C;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.5px;
  line-height: 28px;
  margin-top: 20px;
}
.blog-area-6 .blog-card .blog-content .read-more-area {
  margin-top: 30px;
}
.blog-area-6 .blog-card .blog-content .read-more-area span {
  color: #303334;
  font-size: 16px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -0.53px;
  line-height: normal;
}
.blog-area-6 .blog-card .blog-content .read-more-area i {
  font-size: 18px;
  color: #303334;
}
.blog-area-6 .blog-card .blog-content .read-more-area a {
  text-decoration: none;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
    Blog main     CSS 
        <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.blog-banner {
  padding: 120px 0px 35px 0px;
}
@media (min-width: 480px) {
  .blog-banner {
    padding: 135px 0px 35px 0px;
  }
}
@media (min-width: 768px) {
  .blog-banner {
    padding: 145px 0px 52px 0px;
  }
}
.blog-banner h2 {
  color: #0D152E;
  font-size: 36px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -1.98px;
  line-height: normal;
}
@media (min-width: 576px) {
  .blog-banner h2 {
    font-size: 40px;
  }
}
@media (min-width: 768px) {
  .blog-banner h2 {
    font-size: 60px;
  }
}
.blog-banner p {
  color: #81838C;
  font-size: 20px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.66px;
  line-height: 34px;
}

.blog-main-area {
  padding-top: 60px;
  padding-bottom: 60px;
}
@media (min-width: 768px) {
  .blog-main-area {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}
@media (min-width: 992px) {
  .blog-main-area {
    padding-top: 80px;
    padding-bottom: 130px;
  }
}

.date-comment {
  margin-top: 40px;
}
.date-comment ul {
  margin: 0px -14px -20px -14px;
}
.date-comment ul li {
  display: inline-flex;
  align-items: center;
  margin: 0px 14px 20px 14px;
}
.date-comment span {
  margin-left: 11px;
}

.blog-content-area i {
  font-size: 15px;
  color: #303334;
}
.blog-content-area span {
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  letter-spacing: -0.53px;
  line-height: normal;
}
.blog-content-area span a {
  color: #0D152E;
  text-decoration: none;
}
.blog-content-area h2 {
  font-size: 30px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -1.62px;
  line-height: 42px;
  margin-top: 30px;
}
@media (min-width: 576px) {
  .blog-content-area h2 {
    font-size: 34px;
    line-height: 45px;
  }
}
.blog-content-area h2 a {
  color: #0D152E;
  text-decoration: none;
}
.blog-content-area p {
  color: #687497;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.53px;
  line-height: 28px;
  margin-top: 18px;
}
.blog-content-area .continue-text a {
  display: inline-block;
  text-decoration: none;
  color: #303334;
  font-size: 18px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -0.59px;
  line-height: normal;
  margin-top: 8px;
}

.blog-all-items {
  margin-bottom: -30px;
  padding-top: 35px;
}
@media (min-width: 768px) {
  .blog-all-items {
    padding-top: 0;
  }
}
.blog-all-items .blog-item {
  margin-bottom: 30px;
  border-radius: 15px;
  border: 1px solid #EAECED;
  padding: 30px;
}

@media (min-width: 992px) {
  .blog-right-sidebar {
    padding-left: 0px;
  }
}
@media (min-width: 1200px) {
  .blog-right-sidebar {
    padding-left: 70px;
  }
}
@media (min-width: 1600px) {
  .blog-right-sidebar {
    padding-left: 100px;
  }
}

.search-form .form-control {
  height: 60px;
  border-radius: 5px;
  border: 1px solid #EAECED;
  background-color: #fff;
  color: #81838c;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.53px;
  line-height: normal;
  padding-left: 25px;
}
.search-form button[type=submit] {
  position: absolute;
  right: 0px;
  top: 50%;
  color: #9EA1AB;
  background-color: transparent;
  cursor: pointer;
  transform: translate(-50%, -50%);
  border: 0;
}

.recent-posts h2 {
  color: #0D152E;
  font-size: 20px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -0.66px;
  line-height: normal;
  margin-top: 50px;
  margin-bottom: 35px;
}
.recent-posts .recent-post-item {
  margin-bottom: 30px;
}
.recent-posts .recent-post-item img {
  padding-right: 18px;
}
.recent-posts .recent-post-item .post-content h4 {
  font-size: 18px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -0.59px;
  line-height: normal;
}
.recent-posts .recent-post-item .post-content h4 a {
  color: #0D152E;
  text-decoration: none;
}
.recent-posts .recent-post-item .post-content p {
  color: #81838C;
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  letter-spacing: -0.53px;
  line-height: normal;
  margin-bottom: 0;
}
.recent-posts .recent-post-item .post-content p a {
  color: #81838C;
  text-decoration: none;
}
.recent-posts .recent-post-item .post-content .post-date a {
  margin-left: 11px;
}

.blog-categories {
  margin-top: 50px;
}
@media (min-width: 768px) {
  .blog-categories {
    margin-top: 75px;
  }
}
.blog-categories h2 {
  color: #0D152E;
  font-size: 20px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -0.66px;
  line-height: normal;
  margin-bottom: 30px;
}
.blog-categories .categories-item ul li {
  color: #81838C;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.53px;
  line-height: normal;
  margin-bottom: 25px;
}
.blog-categories .categories-item ul li a {
  color: #81838C;
  text-decoration: none;
}

.blog-pagination {
  padding-top: 40px;
}
@media (min-width: 768px) {
  .blog-pagination {
    padding-top: 60px;
  }
}
.blog-pagination ul {
  margin: 0px -3px 0px -3px;
}
.blog-pagination ul li {
  display: inline-flex;
  margin: 0px 3px 0px 3px;
}
.blog-pagination a {
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 50%;
  text-align: center;
  border: 1px solid #EAECED;
  background-color: #fff;
  text-decoration: none;
  color: #0D152E;
  font-size: 16px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -0.53px;
  transition: all 0.4s ease-in-out;
}
.blog-pagination a:hover {
  background: #303334;
  color: #fff;
}
.blog-pagination a.active {
  background: #303334;
  color: #fff;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
    Blog Details     CSS 
        <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.blog-details-main-area {
  padding-bottom: 25px;
}
@media (min-width: 768px) {
  .blog-details-main-area {
    padding-top: 0px;
    padding-bottom: 45px;
  }
}
@media (min-width: 992px) {
  .blog-details-main-area {
    padding-top: 0px;
    padding-bottom: 95px;
  }
}
@media (min-width: 1200px) {
  .blog-details-main-area .blog-right-sidebar {
    padding-left: 70px;
  }
}
@media (min-width: 1600px) {
  .blog-details-main-area .blog-right-sidebar {
    padding-left: 100px;
  }
}

.blog-details-banner {
  padding: 95px 0px 35px 0px;
}
@media (min-width: 480px) {
  .blog-details-banner {
    padding: 115px 0px 35px 0px;
  }
}
@media (min-width: 768px) {
  .blog-details-banner {
    padding: 140px 0px 55px 0px;
  }
}
@media (min-width: 992px) {
  .blog-details-banner {
    padding: 163px 0px 55px 0px;
  }
}
.blog-details-banner h2 {
  color: #0D152E;
  font-size: 34px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -1.12px;
  line-height: 50px;
}

.blog-sub-details {
  margin-top: 15px;
}
.blog-sub-details ul {
  margin: 0px -5px 0px -5px;
}
.blog-sub-details ul li {
  display: inline-flex;
  margin: 0px 5px 0px 5px;
}
.blog-sub-details ul li a {
  text-decoration: none;
  color: #81838C;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.53px;
  line-height: normal;
}
.blog-sub-details ul li span {
  margin-right: 9px;
}

.blog-details-content {
  padding-top: 60px;
}
@media (min-width: 768px) {
  .blog-details-content {
    padding-top: 0;
  }
}
.blog-details-content p {
  color: #0D152E;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.53px;
  line-height: 28px;
  margin-bottom: 28px;
}
.blog-details-content h4 {
  color: #0D152E;
  font-size: 24px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -0.79px;
  line-height: normal;
  margin-top: 45px;
  margin-bottom: 30px;
}

.blog-single-content-area {
  margin-top: 50px;
}

.comment-area {
  padding-top: 45px;
  padding-bottom: 60px;
}
@media (min-width: 768px) {
  .comment-area {
    padding-top: 65px;
    padding-bottom: 80px;
  }
}
@media (min-width: 992px) {
  .comment-area {
    padding-top: 100px;
    padding-bottom: 130px;
  }
}

.comment-body h2 {
  color: #0D152E;
  font-size: 34px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -1.12px;
  line-height: 50px;
  margin-bottom: 0px;
}
@media (min-width: 768px) {
  .comment-body h2 {
    margin-bottom: 20px;
  }
}
.comment-body label {
  color: #0D152E;
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  letter-spacing: -0.5px;
  line-height: normal;
  margin-bottom: 14px;
  margin-top: 35px;
}
.comment-body .form-control {
  height: 51px;
  border-radius: 5px;
  border: 1px solid #EAECED;
  background-color: #fff;
  color: #81838C;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.53px;
  line-height: normal;
  padding-left: 20px;
}
.comment-body .comment-box .form-control {
  height: 231px;
  padding-left: 24px;
  padding-top: 25px;
}
.comment-body .form-control::placeholder {
  color: #81838C;
  opacity: 1;
}
.comment-body .btn {
  min-width: 180px;
  height: 60px;
  border-radius: 6px;
  background-color: #303334;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -0.53px;
  line-height: normal;
  margin-top: 50px;
}

.blog-comment-list-area {
  padding-top: 48px;
  padding-bottom: 45px;
}
@media (min-width: 768px) {
  .blog-comment-list-area {
    padding-top: 68px;
    padding-bottom: 65px;
  }
}
@media (min-width: 992px) {
  .blog-comment-list-area {
    padding-top: 100px;
    padding-bottom: 100px;
  }
}
.blog-comment-list-area h2 {
  color: #0D152E;
  font-size: 34px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -1.12px;
  line-height: 50px;
  margin-bottom: 35px;
}
@media (min-width: 768px) {
  .blog-comment-list-area h2 {
    margin-bottom: 45px;
  }
}
@media (min-width: 992px) {
  .blog-comment-list-area h2 {
    margin-bottom: 55px;
  }
}

.comment-card {
  border-radius: 5px;
  border: 1px solid #EAECED;
  background-color: #fff;
  padding: 40px 40px 20px 40px;
  margin-bottom: 30px;
}
.comment-card img {
  margin-right: 15px;
}
.comment-card .author-name-date h4 {
  color: #0D152E;
  font-size: 20px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -0.66px;
  line-height: normal;
}
.comment-card .author-name-date span {
  color: #9EA1AB;
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.46px;
  line-height: normal;
}
.comment-card .comment-content p {
  color: #0D152E;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.53px;
  line-height: 28px;
  margin-top: 30px;
}

.comment-load-more-btn .btn {
  color: #303334;
  font-size: 16px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -0.53px;
  line-height: normal;
}
.comment-load-more-btn .btn:hover {
  color: #303334 !important;
}
.comment-load-more-btn i {
  color: #303334;
  margin-left: 22px;
}

/*Calendar css*/
.calendar-area {
  position: relative;
  margin-top: 60px;
}
.calendar-area #calendar {
  background: #fff;
  border-radius: 0;
  border: 1px solid #ddd;
}
.calendar-area .current-date {
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #ddd;
  padding: 15px 10px;
}
.calendar-area .current-month {
  color: #0D152E;
  font-size: 13px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -0.09px;
  line-height: normal;
}
.calendar-area .week-days {
  display: flex;
  justify-content: space-between;
  color: #dfebed;
  font-weight: 600;
  padding: 0px 0px;
}
.calendar-area .week-day {
  padding: 10px;
  text-align: center;
  width: 43px;
  color: #161c2d;
  font-size: 13px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -0.09px;
  line-height: normal;
  text-transform: uppercase;
}
.calendar-area .days {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.calendar-area .weeks {
  display: flex;
  flex-direction: column;
  color: #fff;
  padding: 0 0px;
}
.calendar-area .week {
  display: flex;
  justify-content: space-between;
  font-size: 1.2rem;
  margin-bottom: 0px;
}
.calendar-area .not-current-month {
  opacity: 0.3;
}
.calendar-area .day {
  padding: 10px;
  text-align: center;
  width: 43px;
  color: #81838C;
  font-size: 13px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -0.09px;
  line-height: normal;
}
.calendar-area .day.today {
  width: 41px;
  height: 40px;
  background-color: #303334;
  color: #fff;
}
.calendar-area .day:not(.not-current-month):hover {
  font-weight: 600;
}
.calendar-area #prev,
.calendar-area #next {
  position: absolute;
  background: transparent;
  border: none;
  cursor: pointer;
  color: #81838C;
  padding: 15px;
  font-size: 13px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -0.09px;
  line-height: normal;
  text-align: left;
}
.calendar-area #prev:before {
  content: "<";
  padding-right: 4px;
}
.calendar-area #next {
  right: 0;
}
.calendar-area #next:after {
  content: ">";
  padding-left: 4px;
}
.calendar-area #week-starter {
  position: fixed;
  top: 0;
  right: 0;
  color: #2b4450;
  padding: 20px;
  text-shadow: 2px 2px 1px #dfebed;
}
.calendar-area .week-starter__choice {
  cursor: pointer;
}
.calendar-area .week-starter__choice > input {
  cursor: pointer;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
    SHop main     CSS 
        <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.shop-banner {
  padding: 117px 0px 50px 0px;
}
@media (min-width: 480px) {
  .shop-banner {
    padding: 135px 0px 50px 0px;
  }
}
@media (min-width: 768px) {
  .shop-banner {
    padding: 160px 0px 65px 0px;
  }
}
@media (min-width: 992px) {
  .shop-banner {
    padding: 200px 0px 103px 0px;
  }
}
.shop-banner h2 {
  color: #0D152E;
  font-size: 36px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -1.98px;
  line-height: normal;
}
@media (min-width: 576px) {
  .shop-banner h2 {
    font-size: 40px;
  }
}
@media (min-width: 768px) {
  .shop-banner h2 {
    font-size: 60px;
  }
}

.sort-by-check {
  margin-bottom: 40px;
}

.sort-by-text h6 {
  color: #0D152E;
  font-size: 16px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -0.53px;
  line-height: normal;
}

.sort-by-dropdowown .nice-select {
  background-color: #fff;
  border-radius: 0px;
  border: none;
  height: 42px;
  line-height: 40px;
  padding-left: 18px;
  padding-right: 30px;
  color: #81838C;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.53px;
}
.sort-by-dropdowown .nice-select:after {
  height: 8px;
  width: 8px;
  right: 6px;
  margin-top: -6px;
}

.shop-main-area {
  padding-top: 48px;
  padding-bottom: 60px;
}
@media (min-width: 768px) {
  .shop-main-area {
    padding-top: 63px;
    padding-bottom: 80px;
  }
}
@media (min-width: 992px) {
  .shop-main-area {
    padding-bottom: 130px;
  }
}

.shop-all-items {
  padding-top: 30px;
  margin-bottom: -45px;
}
@media (min-width: 768px) {
  .shop-all-items {
    padding-top: 0;
    margin-bottom: -58px;
  }
}
.shop-all-items .col-lg-4 {
  margin-bottom: 45px;
}
@media (min-width: 768px) {
  .shop-all-items .col-lg-4 {
    margin-bottom: 58px;
  }
}
.shop-all-items .shop-item .product-content h4 {
  color: #0D152E;
  font-size: 20px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -0.66px;
  line-height: normal;
  margin-top: 20px;
}
.shop-all-items .shop-item .product-content h4 a {
  color: #0D152E;
  text-decoration: none;
}
.shop-all-items .shop-item .product-content p {
  color: #0D152E;
  font-size: 16px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -0.53px;
  line-height: normal;
  margin-top: 9px;
  margin-bottom: 12px;
}
.shop-all-items .shop-item .product-content span {
  color: #9EA1AB;
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.46px;
  line-height: normal;
  text-decoration: line-through;
  margin-left: 2px;
}

.product-rating ul {
  margin: 0px -2px 0px -2px;
}
.product-rating ul li {
  display: inline-flex;
  color: #ffac4d;
  margin: 0px 2px 0px 2px;
  cursor: pointer;
}

.search-form .form-control {
  height: 60px;
  border-radius: 5px;
  border: 1px solid #EAECED;
  background-color: #fff;
  color: #81838C;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.53px;
  line-height: normal;
  padding-left: 25px;
}
.search-form button[type=submit] {
  position: absolute;
  right: 0px;
  top: 50%;
  color: #9EA1AB;
  background-color: transparent;
  cursor: pointer;
  transform: translate(-50%, -50%);
  border: 0;
}

.latest-products h2 {
  color: #0D152E;
  font-size: 20px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -0.66px;
  line-height: normal;
  margin-top: 50px;
  margin-bottom: 35px;
}
.latest-products .latest-products-item {
  margin-bottom: 5px;
}
.latest-products .latest-products-item img {
  padding-right: 18px;
}
.latest-products .latest-products-item .latest-products-content h4 {
  font-size: 18px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -0.59px;
  line-height: normal;
  text-align: left;
}
.latest-products .latest-products-item .latest-products-content h4 a {
  color: #0D152E;
  text-decoration: none;
}
.latest-products .latest-products-item .latest-products-content p {
  color: #0D152E;
  font-size: 16px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -0.53px;
  line-height: normal;
}
.latest-products .latest-products-item .latest-products-content span {
  color: #81838C;
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.46px;
  line-height: normal;
  text-align: left;
  text-decoration: line-through;
  margin-left: 3px;
}

.shop-categories {
  margin-top: 50px;
}
@media (min-width: 768px) {
  .shop-categories {
    margin-top: 75px;
  }
}
.shop-categories h2 {
  color: #0D152E;
  font-size: 20px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -0.66px;
  line-height: normal;
  margin-bottom: 30px;
}
.shop-categories .categories-item ul li {
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.53px;
  line-height: normal;
  margin-bottom: 25px;
}
.shop-categories .categories-item ul li a {
  color: #81838C;
  text-decoration: none;
}

.shop-pagination {
  padding-top: 40px;
}
@media (min-width: 768px) {
  .shop-pagination {
    padding-top: 60px;
  }
}
.shop-pagination ul {
  margin: 0px -3px 0px -3px;
}
.shop-pagination ul li {
  display: inline-flex;
  margin: 0px 3px 0px 3px;
}
.shop-pagination a {
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 50%;
  text-align: center;
  border: 1px solid #EAECED;
  background-color: #fff;
  text-decoration: none;
  color: #0D152E;
  font-size: 16px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -0.53px;
  transition: all 0.4s ease-in-out;
}
.shop-pagination a:hover {
  background: #303334;
  color: #fff;
}
.shop-pagination a.active {
  background: #303334;
  color: #fff;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
    SHop single      CSS 
        <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.single-product-slider-area {
  padding-top: 82px;
  padding-bottom: 35px;
}
@media (min-width: 480px) {
  .single-product-slider-area {
    padding-top: 125px;
    padding-bottom: 35px;
  }
}
@media (min-width: 768px) {
  .single-product-slider-area {
    padding-top: 150px;
    padding-bottom: 55px;
  }
}
@media (min-width: 992px) {
  .single-product-slider-area {
    padding-top: 170px;
    padding-bottom: 65px;
  }
}

.product-details-vertical-slider .single-slide {
  margin: 15px 4px 0px 4px;
}
@media (min-width: 768px) {
  .product-details-vertical-slider .single-slide {
    margin: 4px 0px;
  }
}

.single-product-details-area {
  padding-top: 60px;
}
@media all and (max-width: 768px) {
  .single-product-details-area {
    padding-top: 0px;
  }
}
@media (min-width: 768px) {
  .single-product-details-area {
    padding-top: 80px;
  }
}
@media (min-width: 992px) {
  .single-product-details-area {
    padding-top: 0px;
  }
}
.single-product-details-area h2 {
  color: #0D152E;
  font-size: 34px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -1.12px;
  line-height: 50px;
  margin-top: 8px;
  margin-bottom: 13px;
}
.single-product-details-area .single-product-special-rate span {
  color: #d2742f;
  font-size: 14px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -0.46px;
  line-height: normal;
  display: inline-block;
  margin-bottom: 10px;
}
.single-product-details-area .single-product-discount p {
  color: #0D152E;
  font-size: 34px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -1.12px;
  line-height: normal;
  display: flex;
  align-items: center;
}
.single-product-details-area .single-product-discount span {
  color: #81838C;
  font-size: 20px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.66px;
  line-height: normal;
  text-align: left;
  text-decoration: line-through;
  margin-left: 13px;
}
.single-product-details-area .single-product-content p {
  color: #81838C;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.53px;
  line-height: 28px;
  margin-bottom: 20px;
}
.single-product-details-area .single-product-identity .product-iden-title {
  width: 31%;
}
.single-product-details-area .single-product-identity .product-iden-descrip {
  width: 69%;
}
.single-product-details-area .single-product-identity h6 {
  color: #0D152E;
  font-size: 16px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -0.53px;
  line-height: 21px;
  text-align: left;
}
.single-product-details-area .single-product-identity p {
  color: #81838C;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
}

.single-product-btn {
  margin: 20px -8px -15px -8px;
}
.single-product-btn a {
  text-decoration: none;
}
.single-product-btn .btn {
  min-width: 180px;
  height: 60px;
  border-radius: 6px;
  font-size: 16px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -0.53px;
  line-height: normal;
  margin: 0px 8px 15px 8px;
}
.single-product-btn .sin-pro-buy-btn {
  background-color: #303334;
  color: #fff;
}
.single-product-btn .sin-pro-cart-btn {
  border: 1px solid #303334;
  color: #303334;
}
.single-product-btn .sin-pro-cart-btn:hover {
  color: #303334 !important;
}

.product-des-tab-content {
  margin-top: 30px;
}
.product-des-tab-content p {
  color: #0D152E;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.53px;
  line-height: 28px;
  margin-bottom: 22px;
}
@media (min-width: 1600px) {
  .product-des-tab-content p {
    padding-right: 55px;
  }
}

.product-description-area {
  padding-bottom: 30px;
}
@media (min-width: 768px) {
  .product-description-area {
    padding-bottom: 50px;
  }
}
@media (min-width: 992px) {
  .product-description-area {
    padding-bottom: 100px;
  }
}

.product-description-tabs .nav-link {
  color: #81838C;
  font-size: 16px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -0.66px;
  line-height: normal;
  padding-left: 0px;
  padding-right: 0px;
  margin-right: 50px;
  padding-bottom: 20px;
  border-bottom: 1px solid #ddd;
  padding-top: 20px;
  margin-bottom: -1px;
  border: 0;
  margin-right: 30px;
}
@media (min-width: 480px) {
  .product-description-tabs .nav-link {
    font-size: 20px;
  }
}
@media (min-width: 576px) {
  .product-description-tabs .nav-link {
    margin-right: 60px;
  }
}
@media (min-width: 768px) {
  .product-description-tabs .nav-link {
    margin-right: 116px;
  }
}
.product-description-tabs .nav-link:last-child {
  margin-right: 0;
}
.product-description-tabs .nav-link.active {
  border-bottom: 1px solid #0D152E;
  color: #0D152E;
  border-top: 0;
  border-right: 0;
  border-left: 0;
}

.related-products {
  padding-bottom: 55px;
}
@media (min-width: 768px) {
  .related-products {
    padding-bottom: 75px;
  }
}
@media (min-width: 992px) {
  .related-products {
    padding-bottom: 125px;
  }
}
.related-products .section__heading {
  padding-top: 45px;
}
@media (min-width: 768px) {
  .related-products .section__heading {
    padding-top: 65px;
    padding-bottom: 25px;
  }
}
@media (min-width: 992px) {
  .related-products .section__heading {
    padding-top: 90px;
    padding-bottom: 50px;
  }
}
.related-products .shop-all-items {
  margin-bottom: -45px;
}
@media (min-width: 768px) {
  .related-products .shop-all-items {
    margin-bottom: -58px;
  }
}
.related-products .shop-all-items .col-lg-3 {
  margin-bottom: 45px;
}
@media (min-width: 768px) {
  .related-products .shop-all-items .col-lg-3 {
    margin-bottom: 58px;
  }
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
    SHop Cart      CSS 
        <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.shop-cart-area {
  padding-top: 60px;
}
@media (min-width: 768px) {
  .shop-cart-area {
    padding-top: 75px;
  }
}

.product-cart-all-acrd-items {
  margin-bottom: -30px;
}

.cart-product-body {
  margin-bottom: 30px;
  padding: 30px 0px;
  border-top: 1px solid #EAECED;
  border-bottom: 1px solid #EAECED;
}

.cart-details-main-block h3 {
  color: #0D152E;
  font-size: 12px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: 1.2px;
  line-height: normal;
  text-align: left;
  text-transform: uppercase;
  margin-bottom: 20px;
}
.cart-details-main-block h2 {
  color: #182748;
  font-size: 16px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -0.53px;
  line-height: 25px;
  text-align: left;
  padding-right: 30px;
}
@media (min-width: 576px) {
  .cart-details-main-block h2 {
    padding-right: 75px;
  }
}
@media (min-width: 768px) {
  .cart-details-main-block h2 {
    padding-right: 37px;
  }
}
@media (min-width: 992px) {
  .cart-details-main-block h2 {
    padding-right: 100px;
  }
}
.cart-details-main-block h4 {
  color: #182748;
  font-size: 16px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -0.53px;
  line-height: normal;
}
.cart-details-main-block .product-image {
  margin-right: 20px;
}

.quantity-block button {
  border: none;
  height: 30px;
  width: 30px;
  border-radius: 50%;
}

.quantity-block .form-control {
  width: 50px;
  height: 30px;
  border-radius: 3px;
  border: 1px solid #EAECED;
  background-color: #fff;
  margin: 0px 10px;
}

.input--amount-control ::-webkit-inner-spin-button {
  display: none;
}

.cart-details-main-block .cart-product .product-block {
  margin-bottom: 30px;
  flex-basis: 100%;
}
@media (min-width: 480px) {
  .cart-details-main-block .cart-product .product-block {
    flex-basis: 85%;
  }
}
@media (min-width: 576px) {
  .cart-details-main-block .cart-product .product-block {
    flex-basis: 100%;
  }
}
@media (min-width: 768px) {
  .cart-details-main-block .cart-product .product-block {
    margin-bottom: 0;
    flex-basis: 45%;
  }
}
@media (min-width: 992px) {
  .cart-details-main-block .cart-product .product-block {
    flex-basis: 50%;
  }
}
@media (min-width: 1200px) {
  .cart-details-main-block .cart-product .product-block {
    flex-basis: 40%;
  }
}
@media (min-width: 576px) {
  .cart-details-main-block .cart-product .quantity-block {
    flex-basis: 15%;
  }
}
@media (min-width: 768px) {
  .cart-details-main-block .cart-product .quantity-block {
    flex-basis: 15%;
  }
}
@media (min-width: 992px) {
  .cart-details-main-block .cart-product .quantity-block {
    flex-basis: 15%;
  }
}
@media (min-width: 1200px) {
  .cart-details-main-block .cart-product .quantity-block {
    flex-basis: 15%;
  }
}
.cart-details-main-block .cart-product .price-block {
  flex-basis: 20%;
}
@media (min-width: 768px) {
  .cart-details-main-block .cart-product .price-block {
    flex-basis: 15%;
  }
}
@media (min-width: 992px) {
  .cart-details-main-block .cart-product .price-block {
    flex-basis: 15%;
  }
}
@media (min-width: 1200px) {
  .cart-details-main-block .cart-product .price-block {
    flex-basis: 15%;
  }
}
.cart-details-main-block .cart-product .quantity-block {
  flex-basis: 55%;
}
@media (min-width: 480px) {
  .cart-details-main-block .cart-product .quantity-block {
    flex-basis: 40%;
  }
}
@media (min-width: 576px) {
  .cart-details-main-block .cart-product .quantity-block {
    flex-basis: 40%;
  }
}
@media (min-width: 768px) {
  .cart-details-main-block .cart-product .quantity-block {
    flex-basis: 25%;
  }
}
@media (min-width: 992px) {
  .cart-details-main-block .cart-product .quantity-block {
    flex-basis: 25%;
  }
}
@media (min-width: 1200px) {
  .cart-details-main-block .cart-product .quantity-block {
    flex-basis: 25%;
  }
}
@media (min-width: 576px) {
  .cart-details-main-block .cart-product .total-block {
    flex-basis: 15%;
  }
}
@media (min-width: 768px) {
  .cart-details-main-block .cart-product .total-block {
    flex-basis: 15%;
  }
}
@media (min-width: 992px) {
  .cart-details-main-block .cart-product .total-block {
    flex-basis: 10%;
  }
}
@media (min-width: 1200px) {
  .cart-details-main-block .cart-product .total-block {
    flex-basis: 20%;
  }
}
.cart-details-main-block .cart-product .cross-btn-positioning {
  position: absolute;
  top: 50%;
  right: 16px;
  transform: translateY(-50%);
}

.continue-shop-btn .btn {
  min-width: 180px;
  height: 60px;
  border-radius: 6px;
  border: 1px solid #303334;
  color: #303334;
  font-size: 16px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -0.53px;
  line-height: normal;
}
.continue-shop-btn .btn:hover {
  color: #303334 !important;
}

.continue-show-area {
  padding-top: 45px;
  padding-bottom: 60px;
}
@media (min-width: 768px) {
  .continue-show-area {
    padding-bottom: 80px;
  }
}
@media (min-width: 992px) {
  .continue-show-area {
    padding-bottom: 130px;
  }
}

.order-details-card {
  border-radius: 3px;
  border: 1px solid #EAECED;
  background-color: #F7F9FB;
  padding: 20px 20px 25px 20px;
  margin-top: 30px;
}
@media (min-width: 768px) {
  .order-details-card {
    margin-top: 0;
  }
}
.order-details-card h3 {
  color: #0D152E;
  font-size: 20px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -0.66px;
  line-height: normal;
  margin-bottom: 35px;
}
.order-details-card p {
  color: #0D152E;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.53px;
  line-height: normal;
  margin-bottom: 19px;
}
.order-details-card strong {
  color: #0D152E;
  font-size: 16px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -0.53px;
  line-height: normal;
}
.order-details-card .free-color {
  color: #d2742f;
}
.order-details-card h4 {
  color: #0D152E;
  font-size: 20px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -0.66px;
  line-height: normal;
}
.order-details-card .total-price-area {
  margin-top: 15px;
}

.proceed-check-btn .btn {
  height: 60px;
  border-radius: 6px;
  background-color: #303334;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -0.53px;
  line-height: normal;
  width: 100%;
  margin-top: 5px;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
    SHop Checkout      CSS 
        <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.shop-checkout-area {
  padding-top: 50px;
  padding-bottom: 60px;
}
@media (min-width: 768px) {
  .shop-checkout-area {
    padding-top: 70px;
    padding-bottom: 80px;
  }
}
@media (min-width: 992px) {
  .shop-checkout-area {
    padding-top: 80px;
    padding-bottom: 130px;
  }
}
.shop-checkout-area .order-details-card {
  margin-top: 40px;
}
@media (min-width: 768px) {
  .shop-checkout-area .order-details-card {
    margin-top: 50px;
  }
}
@media (min-width: 992px) {
  .shop-checkout-area .order-details-card {
    margin-top: 0;
  }
}

.checkout-form-area h3 {
  color: #0D152E;
  font-size: 24px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -0.79px;
  line-height: normal;
  margin-bottom: 20px;
}
.checkout-form-area label {
  color: #0D152E;
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  letter-spacing: -0.53px;
  line-height: normal;
  margin-bottom: 10px;
  margin-top: 20px;
}
.checkout-form-area .form-control {
  height: 50px;
  border-radius: 5px;
  border: 1px solid #EAECED;
  background-color: #fff;
  padding-left: 15px;
}
.checkout-form-area .form-control ::placeholder {
  color: #81838C;
  opacity: 1;
}
.checkout-form-area .another-card-add {
  margin-top: 33px;
}
.checkout-form-area .checkout-terms-text p {
  color: #81838C;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.53px;
  line-height: normal;
  margin: 30px 0px;
}
.checkout-form-area .checkout-terms-text a {
  color: #0D152E;
  text-decoration: underline;
}
.checkout-form-area .invisible {
  margin: 0;
}
@media (min-width: 576px) {
  .checkout-form-area .invisible {
    margin-bottom: 10px;
    margin-top: 20px;
  }
}
.checkout-form-area .payment-card-img {
  width: 48%;
}
@media (min-width: 576px) {
  .checkout-form-area .payment-card-img {
    width: initial;
  }
}

.payment-form-control {
  height: 71px !important;
}
.payment-form-control label {
  margin-top: 0;
  margin-bottom: 0;
  margin-left: -1px;
}
.payment-form-control span {
  margin-right: 15px;
  color: #0D152E;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.53px;
  line-height: normal;
}

.visa-card-position {
  position: absolute;
  top: 0;
  right: 10px;
}

.question-icon-position {
  position: absolute;
  top: 50%;
  right: 16px;
  transform: translateY(-50%);
}

.checkout-form-area .payment-head {
  margin-top: 55px;
  margin-bottom: 25px;
}
.checkout-form-area .nice-select {
  width: 100%;
  height: 50px;
  line-height: 50px;
  padding-left: 15px;
  color: #0D152E;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.53px;
}
.checkout-form-area .nice-select .list {
  width: 100%;
  height: 180px;
  overflow-y: scroll;
}
.checkout-form-area .nice-select:after {
  border-bottom: 2px solid #000;
  border-right: 2px solid #000;
  width: 6px;
  height: 6px;
  right: 16px;
}

.complte-checkout-btn .btn {
  height: 60px;
  border-radius: 6px;
  background-color: #303334;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -0.53px;
  line-height: normal;
}

.shop-all-items .product-image a {
  position: relative;
  overflow: hidden;
  height: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (min-width: 768px) {
  .shop-all-items .product-image a {
    height: 200px;
  }
}
@media (min-width: 1200px) {
  .shop-all-items .product-image a {
    height: 300px;
  }
}
.shop-all-items .product-image a img {
  width: 100%;
  height: 100%;
  position: absolute;
  object-fit: cover;
  z-index: 0;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
  Thank you page    CSS 
        <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.thank-you-page {
  padding-top: 108px;
  padding-bottom: 60px;
}
@media (min-width: 480px) {
  .thank-you-page {
    padding-top: 125px;
  }
}
@media (min-width: 576px) {
  .thank-you-page {
    padding-top: 130px;
  }
}
@media (min-width: 768px) {
  .thank-you-page {
    padding-top: 150px;
    padding-bottom: 80px;
  }
}
@media (min-width: 992px) {
  .thank-you-page {
    padding-top: 245px;
    padding-bottom: 175px;
  }
}

.thankyou-content h2 {
  color: #0D152E;
  font-size: 40px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -2.97px;
  line-height: normal;
  margin-top: 30px;
}
@media (min-width: 576px) {
  .thankyou-content h2 {
    font-size: 48px;
  }
}
@media (min-width: 992px) {
  .thankyou-content h2 {
    font-size: 90px;
  }
}
.thankyou-content p {
  color: #81838C;
  font-size: 20px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.66px;
  line-height: 34px;
}

.back-to-btn .btn {
  min-width: 180px;
  height: 60px;
  border-radius: 6px;
  border: none;
  color: #303334;
  font-size: 16px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -0.53px;
  line-height: normal;
  margin-top: 25px;
}
.back-to-btn .btn:hover {
  color: #303334 !important;
}
.back-to-btn a {
  text-decoration: none;
}

.splash-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: auto;
  width: 100%;
  height: auto;
  padding: 0.5rem;
  text-align: center;
  background-color: white;
  z-index: 3;
}

.splash {
  display: flex;
  position: relative;
}
.splash .item {
  width: 50%;
  padding: 2em;
  color: #ffffff;
  background: #eeeeee;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 2rem;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.splash .item span {
  z-index: 3;
  text-shadow: 1px 1px 4px rgb(0, 0, 0);
}
.splash .item:before {
  content: "";
  background-color: black;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  opacity: 0.5;
  transition-duration: 0.5s;
}
.splash .item:hover:before {
  opacity: 0;
}

@media (max-width: 767px) {
  .splash {
    flex-wrap: wrap;
    margin-top: 2vh;
  }
  .splash .item {
    width: 100%;
    height: 49vh;
  }
}
.hero-block {
  padding-top: 0;
}
.hero-block .hero-image {
  width: 100vw;
  height: auto;
}
.hero-block.video-container {
  position: relative;
  overflow: hidden;
  padding-top: 2rem;
  height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.hero-block.video-container video, .hero-block.video-container img {
  width: 100%;
  height: 100%;
  position: absolute;
  object-fit: cover;
  z-index: 0;
}
.hero-block.video-container .container {
  z-index: 1;
  position: relative;
  text-align: center;
  color: #dc0000;
  padding: 10px;
}
.hero-block .container-heading {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 1;
  padding-top: 3rem;
  padding-left: 1rem;
  padding-right: 1rem;
}
.hero-block .container-heading h2 {
  font-size: 2rem;
  font-weight: 600;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
}
.hero-block .container-heading .text {
  text-align: center;
  font-size: 1.2rem;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
}
@media (min-width: 992px) {
  .hero-block .container-heading h2 {
    font-size: 4rem;
  }
  .hero-block .container-heading .text {
    font-size: 1.5rem;
  }
}

.feature-block {
  padding: 3rem 0;
}
.feature-block i {
  font-size: 1.25rem;
}

.nice-select .option a {
  color: #81838c;
}

.sort-by-dropdowown .nice-select {
  height: 37px;
  line-height: 31px;
}
.sort-by-dropdowown .nice-select:after {
  margin-top: -9px;
}

.custom-blog-post {
  display: block;
  color: black;
  border: 1px solid #eaeced;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
  gap: 0;
}
@media (min-width: 768px) {
  .custom-blog-post {
    gap: 15px;
  }
}
.custom-blog-post > * {
  color: black;
}
.custom-blog-post img {
  border-radius: 15px;
}
.custom-blog-post .content-area {
  padding: 1rem;
}
.custom-blog-post .date-area li {
  font-size: 0.9rem;
}
.custom-blog-post .continue-text {
  display: inline-block;
  border-bottom: 2px solid #d2742f;
  margin-top: 0.5rem;
  color: black;
}
.custom-blog-post.left-layout img {
  width: 50%;
  height: auto;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.custom-blog-post.left-layout .content-area {
  width: 50%;
}
.custom-blog-post.right-layout img {
  width: 50%;
  height: auto;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  order: 2;
}
.custom-blog-post.right-layout .content-area {
  width: 50%;
  order: 1;
}
@media (max-width: 767px) {
  .custom-blog-post.left-layout, .custom-blog-post.right-layout {
    flex-wrap: wrap;
    justify-content: flex-start;
  }
  .custom-blog-post.left-layout img, .custom-blog-post.right-layout img {
    width: 100%;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    order: 1;
  }
  .custom-blog-post.left-layout .content-area, .custom-blog-post.right-layout .content-area {
    order: 2;
    width: 100%;
  }
}
@media (min-width: 1200px) {
  .custom-blog-post.left-layout .content-area, .custom-blog-post.right-layout .content-area {
    font-size: 1.2rem;
  }
}
.custom-blog-post.cover-layout {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  min-height: 250px;
  gap: 0;
  position: relative;
}
.custom-blog-post.cover-layout .content-area {
  width: 70%;
  position: absolute;
  bottom: 1rem;
  left: 1rem;
}
.custom-blog-post.cover-layout .content-area > * {
  color: white;
}
@media (min-width: 992px) {
  .custom-blog-post.cover-layout {
    min-height: 350px;
  }
}
.custom-blog-post.detail-layout-right {
  border: none;
}
.custom-blog-post.detail-layout-right img {
  border-radius: 15px;
}
@media (min-width: 768px) {
  .custom-blog-post.detail-layout-right {
    align-items: flex-start;
    display: block;
  }
  .custom-blog-post.detail-layout-right img {
    float: right;
    margin-left: 20px;
    margin-bottom: 20px;
  }
  .custom-blog-post.detail-layout-right .content-area {
    width: 100%;
  }
}
.custom-blog-post.detail-layout-left {
  border: none;
}
.custom-blog-post.detail-layout-left img {
  border-radius: 15px;
}
@media (min-width: 768px) {
  .custom-blog-post.detail-layout-left {
    align-items: flex-start;
    display: block;
  }
  .custom-blog-post.detail-layout-left img {
    float: left;
    margin-right: 30px;
    margin-bottom: 20px;
    order: 1;
  }
  .custom-blog-post.detail-layout-left .content-area {
    width: 100%;
    order: 2;
  }
}

.blog-description > * {
  color: #1d263a;
  text-align: justify;
}

.slick-buttons.slick-prev {
  left: -35px;
}
.slick-buttons.slick-next {
  right: -35px;
}