.product-carousel-block {
  padding: 1rem 0 1rem;

  /* Slick slider styles */
  .product-carousel {
    position: relative;
    display: block;
    box-sizing: border-box;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
  }

  .slick-list {
    position: relative;
    overflow: hidden;
    display: block;
    margin: 0;
    padding: 0;
  }

  .slick-list:focus {
    outline: none;
  }

  .slick-list.dragging {
    cursor: pointer;
    cursor: hand;
  }

  .slick-slider .slick-track,
  .slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  .slick-track {
    position: relative;
    top: 0;
    left: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .slick-track:before,
  .slick-track:after {
    display: table;
    content: '';
  }

  .slick-track:after {
    clear: both;
  }

  .slick-slide {
    float: left;
    height: 100%;
    min-height: 1px;
    margin: 0 10px;
    display: block;
    overflow: hidden;
  }

  [dir='rtl'] .slick-slide {
    float: right;
  }

  .slick-slide img {
    display: block;
    max-width: 100%;
    height: auto;
    transition: transform 0.2s ease-out;
  }

  .slick-slide img:hover {
    transform: scale(1.1);
  }

  .slick-dots {
    display: block;
    text-align: center;
    margin: 10px 0;
    padding: 0;
    list-style: none;
  }

  .slick-dots li {
    position: relative;
    display: inline-block;
    margin: 0 5px;
    padding: 0;
    cursor: pointer;
  }

  .slick-dots li button {
    font-size: 0;
    line-height: 0;
    display: block;
    width: 10px;
    height: 10px;
    padding: 5px;
    cursor: pointer;
    color: transparent;
    border: 0;
    outline: none;
    background: #bbb;
    border-radius: 50%;
  }

  .slick-dots li button:hover,
  .slick-dots li button:focus {
    outline: none;
  }

  .slick-dots li button:hover:before,
  .slick-dots li button:focus:before {
    opacity: 1;
  }

  .slick-dots li button:before {
    font-size: 6px;
    line-height: 20px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: '•';
    text-align: center;
    opacity: 0.25;
    color: #fff;
    transition: all 0.3s ease;
  }

  .slick-dots li.slick-active button:before {
    opacity: 0.75;
    color: #fff;
  }

}




@media all and (max-width:768px) {
  .product-details-slider {
    display: none;
  }

  .product-details-slider.slick-initialized {
    display: none;
  }
}

@media all and (min-width:769px) {
  .product-details-slider {
    display: none;
  }

  .product-details-slider.slick-initialized {
    display: block;
  }

  .product-details-vertical-slider {
    display: none;
  }

  .product-details-vertical-slider.slick-initialized {
    display: block;
  }
}