/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
  Thank you page    CSS 
        <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/


.thank-you-page {
    padding-top: 108px;
    padding-bottom: 60px;
    
  @include mobile {
    padding-top: 125px;
}
    @include mobile-lg {
        padding-top: 130px;
    }
    @include tablet {
        padding-top: 150px;
        padding-bottom: 80px;
    }
  
    @include desktops {
        padding-top: 245px;
    padding-bottom: 175px;
    }
}

.thankyou-content {
    h2 {
        color: $haiti;
        font-size: 40px;
        font-weight: 700;
        font-style: normal;
        letter-spacing: -2.97px;
        line-height: normal;
        margin-top: 30px;
        @include mobile-lg {
            font-size: 48px;
        }
        @include desktops {
            font-size: 90px;
        }
    }

    p {
        color: $waterloo;
        font-size: 20px;
        font-weight: 400;
        font-style: normal;
        letter-spacing: -0.66px;
        line-height: 34px;
    }
}

.back-to-btn {
    .btn {
        min-width: 180px;
        height: 60px;
        border-radius: 6px;
        border: none;
        color: $primary;
        font-size: 16px;
        font-weight: 700;
        font-style: normal;
        letter-spacing: -0.53px;
        line-height: normal;
        margin-top: 25px;
        &:hover{
            color: $primary !important;
        }
    }
    a{
        text-decoration: none;
    }
}