.splash-header{
    position:fixed;
    top:0;
    left:0;
    right:0;
    bottom:auto;
    width:100%;
    height:auto;
    padding:0.5rem;
    text-align: center;
    background-color:rgba(white, 100%);
    z-index: 3;
}

.splash {
    display: flex;
    position: relative;
    .item {
      width: 50%;
      padding: 2em;
      color: #ffffff;
      background: #eeeeee;
      height: 100vh;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 600;
      font-size: 2rem;
      position: relative;
      background:{
        repeat:no-repeat;
        size:cover;
        position:center;
      }
      span{
          z-index: 3;
          text-shadow: 1px 1px 4px rgba(0, 0, 0, 1);
      }
      &:before{
        content:"";
        background-color: black;
        position: absolute;
        top:0;
        right:0;
        bottom:0;
        left:0;
        z-index: 1;
        opacity: 0.5;
        transition-duration:0.5s;
      }
      &:hover{
        &:before{
            opacity:0;
        }
      }
    }
}
  

@include for-phone-only {
    .splash {
        flex-wrap: wrap;
        margin-top:2vh;
        .item {
            width: 100%;
            height:49vh;
        }
    }
}