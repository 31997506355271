@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.3/font/bootstrap-icons.css");

@import "modules/variables";

// Import Bootstrap !not needed if you use BS UI, import the UI instead

// @import 'theme/main.scss';


// Modules
@import "modules/media_query_mixins";
@import "modules/theme-media-query";
@import "modules/typography";
@import "modules/buttons";
@import "modules/form";
@import "modules/logo";

// Blocks
// @import "blocks/masonry";

@import "modules/global";
@import "modules/slick";

@import "pages/about-us";
@import "pages/contact";
@import "pages/blog";
@import "pages/shop";
@import "pages/thankyou";
@import "pages/splash";

@import "blocks/hero-block";
@import "blocks/feature-block";
@import "blocks/workinprogress-block";

.nice-select .option a {
    color:#81838c;
}
.sort-by-dropdowown .nice-select {
    height: 37px;
    line-height: 31px;
    &:after {
        margin-top: -9px;
    }
}

.custom-blog-post{
    display: block;
    color:black;
    border:1px solid #eaeced;
    border-radius:15px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1rem;
    gap:0;
    @include for-tablet-portrait-up {
        gap:15px;
    }
    > *{
        color:black;
    }
    img{
        border-radius:15px;
    }
    .content-area{
        padding:1rem;
    }
    .date-area{
        li{
            font-size: 0.9rem;
        }
    }
    .continue-text{
        display: inline-block;
        border-bottom:2px solid $secondary;
        margin-top: 0.5rem;
        color:black;
    }
    &.left-layout{
        img{
            width:50%;
            height:auto;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }
        .content-area{
            width:50%;
        }
    }
    &.right-layout{
        img{
            width:50%;
            height:auto;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            order:2;
        }
        .content-area{
            width:50%;
            order:1;
        }
    }
    &.left-layout,
    &.right-layout {
        @include for-phone-only {
            flex-wrap: wrap;
            justify-content: flex-start;
            img{
                width: 100%;
                border-top-left-radius: 15px;
                border-top-right-radius: 15px;
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;
                order:1;
            }
            .content-area{
                order:2;
                width:100%;
            }
        }
        @include for-desktop-up {
            img{
                
            }
            .content-area{
                font-size: 1.2rem;
            }
        }
    }

    &.cover-layout{
        background:{
            repeat:no-repeat;
            size:cover;
            position:center;
        }
        min-height:250px;
        gap:0;
        position: relative;
        .content-area{
            width:70%;
            position: absolute;
            bottom:1rem;
            left:1rem;
            > * {
                color:white;
            }
        }
        @include for-tablet-landscape-up {
            min-height:350px;
        }
    }

    &.detail-layout-right{
        border:none;
        img{
            border-radius:15px;
        }
        @include for-tablet-portrait-up {
            align-items: flex-start;
            display: block;
            img{
                float:right;
                margin-left: 20px;
                margin-bottom: 20px;
            }
            .content-area{
                width:100%;
            }
        }
    }
    &.detail-layout-left{
        border:none;
        img{
            border-radius:15px;
        }
        @include for-tablet-portrait-up {
            align-items: flex-start;
            display: block;
            img{
                float:left;
                margin-right: 30px;
                margin-bottom: 20px;
                order:1;
            }
            .content-area{
                width:100%;
                order:2;
            }
        }
    }
}

.blog-description{
    > * {
        color:#1d263a;
        text-align: justify;
    }
}

.slick-buttons{
    &.slick-prev{
        left:-35px;
    }
    &.slick-next{
        right:-35px;
    }
}