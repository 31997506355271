.logo {
    height: 1.5rem;
}

.logo-small {
    height: 1.5rem;
}

.main-image {
    background-image: url("./images/CRAFTURE_DB_Render_001.png");
}

.theme-color-orange {
    color: rgb(210, 116, 47) !important;
}

.bg-theme-color-orange {
    background-color: rgb(210, 116, 47) !important;
}

.bg-gray-color {
    background-color: rgb(210, 210, 210);
}

.bg-gray-color-transparant {
    background-color: rgb(210, 210, 210, 0.4) !important;
}

.pb-15 {
    padding-bottom: 1.5rem !important;
}

/* Make logo smaller for smaller screens */
@media only screen and (min-width: 576px) {
    .logo {
        height: 2rem;
    }
}

.bg-light-color-orange {
    background-color: rgb(250, 229, 218, 0.4) !important;
}

/* rgb(210 116 47) */

.inner-contact-form-area-1 {
    background: url(../image/latest/contact-bg-2.png);
    background-color: rgba(0, 0, 0, 0);
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-size: cover;
    background-origin: padding-box;
    background-clip: border-box;
}

/* Location search area */
.center-flex {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 1em;
}

.text-white {
    color: white;
}



.form-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    width: 100%;
    max-width: 768px;
}

.form-section {
    margin: 0.5rem;
}

.form-section-heading {
    font-size: 1rem;
    font-weight: 600;
    text-align: center;
}

.max-width {
    max-width: 576px;
}

/* Custom by @Nevron */

@media (max-width: 991px) {
    .reveal-header .mobile-menu-trigger span {
        background-color: black !important;
    }

    .reveal-header .mobile-menu-trigger span:before,
    .reveal-header .mobile-menu-trigger span:after {
        background-color: black !important;
    }
}

.section__heading {
    h2 {
        font-size: 2.2rem;
    }
}

.header-btn {
    .dropdown-toggle {
        background: none;

        &:focus {
            box-shadow: none;
        }

        &:hover {
            box-shadow: none;
        }
    }
}

.tab-content {
    ul {
        list-style-type: disc;

        li {
            list-style-type: disc;
        }
    }
}

@media all and (max-width:768px) {
    .footer-logo {
        text-align: center;

        .logo-reveal {
            scale: 0.7;
        }
    }

}

.footer-copyright-wrapper {
    margin-top: 2rem;
}

.footer-content-l5 {
    .copyright-text-l5 {
        @media all and (max-width:767px) {
            margin-bottom: 2rem;
        }

        @media all and (min-width:992px) {
            padding-left: 3rem;
        }

        @media all and (min-width:1200px) {
            padding-left: 4rem;
        }

        @media all and (min-width:1400px) {
            padding-left: 5rem;
        }
    }
}


@media all and (max-width:767px) {
    .product-carousel-block {
        .product-carousel {
            padding: 2rem;

            .slick-buttons.slick-prev {
                left: 0;
            }

            .slick-buttons.slick-next {
                right: 0;
            }
        }
    }

    .product-details-vertical-slider {
        padding: 1rem 2rem;

        .slick-buttons.slick-prev {
            left: 0;
        }

        .slick-buttons.slick-next {
            right: 0;
        }
    }
}


@media all and (min-width: 1200px) {
    .custom-nav .site-menu-main .nav-link-item {
        padding: 10px 20px !important;
    }
}