@mixin for-small-phone-only {
    @media (max-width: 329px) {
      @content;
    }
  }
  
  @mixin for-phone-up {
    @media (min-width: 330px) {
      @content;
    }
  }
  
  @mixin for-big-phone-up {
    @media (min-width: 375px) {
      @content;
    }
  }
  
  @mixin for-small-tablet-up {
    @media (min-width: 576px) {
      @content;
    }
  }

  @mixin for-small-tablet-only {
    @media (min-width: 576px) and (max-width: 767px) {
      @content;
    }
  }
  
  @mixin for-phone-only {
    @media (max-width: 767px) {
      @content;
    }
  }
  
  @mixin for-tablet-portrait-up {
    @media (min-width: 768px) {
      @content;
    }
  }
  
  @mixin for-tablet-portrait-only {
    @media (min-width: 768px) and (max-width: 991px) {
      @content;
    }
  }
  
  @mixin for-tablet-landscape-up {
    @media (min-width: 992px) {
      @content;
    }
  }
  
  @mixin for-tablet-landscape-only {
    @media (min-width: 992px) and (max-width: 1024px) {
      @content;
    }
  }
  
  @mixin for-small-desktop-up {
    @media (min-width: 1025px) {
      @content;
    }
  }
  
  @mixin for-desktop-up {
    @media (min-width: 1200px) {
      @content;
    }
  }
  
  @mixin for-desktop-retina-up {
    @media
    only screen and (-webkit-min-device-pixel-ratio: 2)      and (min-width: 1300px),
    only screen and (   min--moz-device-pixel-ratio: 2)      and (min-width: 1300px),
    only screen and (     -o-min-device-pixel-ratio: 2/1)    and (min-width: 1300px),
    only screen and (        min-device-pixel-ratio: 2)      and (min-width: 1300px),
    only screen and (                min-resolution: 192dpi) and (min-width: 1300px),
    only screen and (                min-resolution: 2dppx)  and (min-width: 1300px) {
      @content;
    }
  }
  
  @mixin for-desktop-only {
    @media (min-width: 1025px) and (max-width: 1439px) {
      @content;
    }
  }
  
  @mixin for-big-desktop-up {
    @media (min-width: 1440px) {
      @content;
    }
  }
  
  @mixin for-bigger-desktop-up {
    @media (min-width: 1650px) {
      @content;
    }
  }
  
  @mixin for-ultrawide-up {
    @media (min-width: 2560px) {
      @content;
    }
  }
  
  
  @mixin for-ie {
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      @content;
    }
  }
  @mixin for-ie-tablet-portrait-up {
    @media all and (min-width: 768px) and (-ms-high-contrast: none),
      (-ms-high-contrast: active) {
      @content;
    }
  }
  @mixin for-ie-tablet-landscape-up {
    @media all and (min-width: 992px) and (-ms-high-contrast: none),
      (-ms-high-contrast: active) {
      @content;
    }
  }
  @mixin for-ie-desktop-up {
    @media all and (min-width: 1200px) and (-ms-high-contrast: none),
      (-ms-high-contrast: active) {
      @content;
    }
  }
  @mixin for-ie-big-desktop-up {
    @media all and (min-width: 1440px) and (-ms-high-contrast: none),
      (-ms-high-contrast: active) {
      @content;
    }
  }
  @mixin for-ie-bigger-desktop-up {
    @media all and (min-width: 1650px) and (-ms-high-contrast: none),
      (-ms-high-contrast: active) {
      @content;
    }
  }
  