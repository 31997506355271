.hero-block{
    padding-top: 0;
    .hero-image{
        width:100vw;
        height:auto;
    }
    &.video-container {
        position: relative;
        overflow: hidden;
        padding-top: 2rem;
        height: 50vh;
        display: flex;
        align-items: center;
        justify-content: center;
        video, img{
            width: 100%;
            height: 100%;
            position: absolute;
            object-fit: cover;
            z-index: 0;
        }
        .container{
            z-index: 1;
            position: relative;
            text-align: center;
            color: #dc0000;
            padding: 10px;
        }
    }
    
    .container-heading {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        z-index: 1;
        padding-top: 3rem;
        padding-left: 1rem;
        padding-right: 1rem;
        h2{
            font-size: 2rem;
            font-weight: 600;
            text-shadow: 2px 2px 4px rgba(0,0,0,0.2);
        }
        .text{
            text-align: center;
            font-size: 1.2rem;
            text-shadow: 2px 2px 4px rgba(0,0,0,0.3);
        }
        
        @include for-tablet-landscape-up {
            h2{
                font-size: 4rem;
            }
            .text{
                font-size: 1.5rem;
            }
        }
    }
}
