.logo-reveal{
  position:relative;
  scale: 0.5;
  margin-left: -1rem;
  margin-right: -1rem;
  .main-logo{
    width:320px;
    height:40px;
  }

  .left-bar-wrapper,
  .right-bar-wrapper{
    transition: position 2s;
    position: absolute;
    display: inline-block;
    &:before{
      content:'';
      display: inline-block;
      z-index: 1;
      position:absolute;
      top:0;
      bottom:0;
      background-color:#e26e0d;
      transition: width 2s ease-out;
      width:180px;
      height:40px;
    }
    .left-bar,
    .right-bar{
      display: inline-block;
      width:23px;
      height:40px;
      position: absolute;
      top:0;
      bottom:0;
      z-index: 2;
    }
  }
  .left-bar-wrapper{
    &:before{
      left: -23px;
    }
    .left-bar{
      left:150px;
      transition: left 2s ease-out;
    }
  }
  .right-bar-wrapper{
    &:before{
      right: -23px;
    }
    .right-bar{
      right:150px;
      transition: right 2s ease-out;
    }
  }
  &.reveal{
    .left-bar-wrapper,
    .right-bar-wrapper{
      &:before{
        width:0;
      }
    }
    .left-bar-wrapper{
      .left-bar{
        left:-26px;
      }
    }
    .right-bar-wrapper{
      .right-bar{
        right:-26px;
      }
    }
  }
}

.navbar{
  .brand-logo{
    .logo-reveal{
      margin-left: -4rem;
    }
  }
}