$primary: #303334;
$secondary: #d2742f;
$lightgreen: #79a48f;
$cream:  #f1ece1;
$darkercream: #928e85;
$grey: #374345;

$dark: $grey;

$gray-900:$grey;

$white :#fff;
$black :#000;
$green :green;
$bunting :#0E1833;
$bunting_2 :#162647;
$bunting-aprx :#101C3D;
$porcelain :#EAECED;
$haiti :#0D152E;
$waterloo :#81838C;
$aluminium :#A8ABB3;
$orange :#EF8E1F;
$jaffa :#F48941;
$rajah :#F7B26D;
$mercury :#E5E5E5;
$shamrock :#29CE76;
$portica :#F7E36D;
$biscay :#1D2E5E;
$catalinablue :#082B91;
$wildasand :#F5F5F5;
$rhino :#283350;
$alabaster :#FBFBFB;
$goldendream :#EED22D;
$electricviolet :#9726EC;
$catskillwhite :#F7F9FB;
$yellow-orange :#FBB040;
$santas-gray :#9EA1AB;
$lynch :#687497;
$big-stone :#182748;


.bg-catskillwhite{
    background-color: $catskillwhite;
}
